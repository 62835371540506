<template>
    <div>
        <section id="page-title" class="page-title-parallax page-title-dark" style="background-image: url('/assets/images/about/parallax.jpg'); padding: 120px 0;" data-bottom-top="background-position:0px 300px;" data-top-bottom="background-position:0px -300px;">

			<div class="container clearfix">
				<h1>Job Openings</h1>
				<span>Join our Fabulous Team of Intelligent Individuals</span>
				<ol class="breadcrumb">
					<li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
					<li class="breadcrumb-item active" aria-current="page">Jobs</li>
				</ol>
			</div>

		</section><!-- #page-title end -->
        <!-- Content
		============================================= -->
		<section id="content">
			<div class="content-wrap">
				<div class="container clearfix">
                    
					<div class="row col-mb-50">
                        <!-- sidebar -->
                        <div class="col-md-4">
                            <Sidebar/>
                        </div>
                        <!-- JD  -->
						<div class="col-md-8">
							<div>
                                <h3 style="width: 100%;padding: 0 0 0.75rem;border-bottom: 2px solid #1ABC9C;">Software Security Engineer
                                <router-link to="/careers/software-security-engineer/form" class="button button-3d m-0 button-primary" style="float:right">Apply Now</router-link></h3>
                                <h4>Who we Are</h4>
                                <p>eFlexervices is a BPO company with a legacy spanning 22 years, we've honed our craft in providing exceptional quality and building unshakable trust. At eFlex, we're not just a BPO company – we're your partners in success. Our approach is all about finding the perfect match between talent and the organizations we support. We're not just investing in our work; we're investing in people, optimizing performance, and maximizing efficiency. We are all about exceptional quality and unwavering trust.</p>
                                <h4>What We Are Looking For</h4>
                                <p>Are you ready to take your expertise to the next level? At eFlexervices, we're not just hiring for a <strong>Software Security Engineer</strong> - we're empowering your talent to deliver quality and performance. Join us in shaping the success and making an impact that truly matters.</p>
                                <p>We are seeking an experienced Software Security Engineer to join our team. In this role, you will play a crucial part in ensuring the security of our software systems by analyzing designs and implementations from a security perspective. Your primary responsibilities will include managing our software library on Github, assessing software vulnerabilities through code scanning and dependency analysis, and coordinating with the engineering team to address identified issues. Additionally, you will oversee antivirus endpoint security solutions, monitor server vulnerabilities, and enhance our security platform using HackerOne.</p>

                                <div class="accordion accordion-bg">

                                    <div class="accordion-header">
                                        <div class="accordion-icon">
                                            <i class="accordion-closed icon-ok-circle"></i>
                                            <i class="accordion-open icon-remove-circle"></i>
                                        </div>
                                        <div class="accordion-title">
                                            What you'll be doing
                                        </div>
                                    </div>
                                    <div class="accordion-content">
                                        <ul class="iconlist iconlist-color mb-0">
                                            <li><i class="icon-ok"></i>Manage the software library on Github:
                                                <ul>
                                                    <li>- Assess software vulnerabilities: code scanning, secret scanning, and dependency libraries.</li>
                                                    <li>- Check the repository for vulnerable codes and coordinate with the engineering team to make the necessary changes.</li>
                                                </ul>
                                            </li>
                                            <li><i class="icon-ok"></i>Manage antivirus endpoint security solutions: Crowdstrike:
                                                <ul>
                                                    <li>- Monitor and coordinate with the engineering team for any vulnerabilities on the servers they manage.</li>
                                                    <li>- Create and manage fusion workflows for better monitoring and to improve the current setup. </li>
                                                </ul>
                                            </li>
                                            <li><i class="icon-ok"></i>Manage security platform and hacker program: HackerOne:
                                                <ul>
                                                    <li>- Receive and assess bug reports from security researchers.</li>
                                                    <li>- Coordinate the information between security researchers and the engineering team until the bug is fixed.</li>
                                                </ul>
                                            </li>
                                            <li><i class="icon-ok"></i>Implement, test, and operate advanced software security techniques in compliance with the technical reference architecture.</li>
                                            <li><i class="icon-ok"></i>Perform ongoing security testing and code review to improve software security.</li>
                                            <li><i class="icon-ok"></i>Maintain technical documentation.</li>
                                            <li><i class="icon-ok"></i>Consult with team members on secure coding practices.</li>
                                            <li><i class="icon-ok"></i>Develop familiarity with new tools and best practices.</li>
                                        </ul>
                                    </div>

                                    <div class="accordion-header">
                                        <div class="accordion-icon">
                                            <i class="accordion-closed icon-ok-circle"></i>
                                            <i class="accordion-open icon-remove-circle"></i>
                                        </div>
                                        <div class="accordion-title">
                                            Qualifications and Requirements
                                        </div>
                                    </div>
                                    <div class="accordion-content">
                                        <ul class="iconlist iconlist-color mb-0">
                                            <li><i class="icon-ok"></i>Proven work experience as a software security engineer</li>
                                            <li><i class="icon-ok"></i>Detailed technical knowledge of techniques, standards, and state-of-the-art capabilities for authentication and authorization, applied cryptography, security vulnerabilities, and remediation</li>
                                            <li><i class="icon-ok"></i>Software development experience in one of the following core languages: Ruby, Java, Javascript, or Python</li>
                                            <li><i class="icon-ok"></i>Adequate knowledge of web-related technologies (web applications, web services, and service-oriented architectures) and network and web-related protocols</li>
                                            <li><i class="icon-ok"></i>Interest in all aspects of security research and development</li>
                                            <li><i class="icon-ok"></i>BS degree in computer science or a related field</li>
                                        </ul>
                                    </div>
                                    
                                    <div class="accordion-header">
                                        <div class="accordion-icon">
                                            <i class="accordion-closed icon-ok-circle"></i>
                                            <i class="accordion-open icon-remove-circle"></i>
                                        </div>
                                        <div class="accordion-title">
                                            What you'll enjoy at eFlex
                                        </div>
                                    </div>
                                    <div class="accordion-content">
                                        <p>At eFlex, we're not just a workplace - we're a community of playmakers, committed to fostering growth, learning, and personal connections. Here's what's in store for you:</p>
                                        <ul class="iconlist iconlist-color mb-0">
                                            <li><i class="icon-plus-sign"></i><strong>Living Our Values:</strong> We don't just talk the talk; we walk the walk. From learning and personal growth to caring deeply about our team and clients, our values are the compass guiding our vibrant workplace.</li>
                                            <li><i class="icon-plus-sign"></i><strong>Competitive Compensation:</strong> Your hard work deserves recognition. Enjoy a competitive salary and benefits package, including comprehensive HMO coverage and optical reimbursements.</li>
                                            <li><i class="icon-plus-sign"></i><strong>Flexible Work Options:</strong> Work your way! If you're outside Baguio City, embrace a permanent work-from-home setup. For those within Baguio, our hybrid work model offers the best of both worlds.</li>
                                            <li><i class="icon-plus-sign"></i><strong>Wellness Matters:</strong> Recharge and refresh! Our flexible vacation and sick leaves empower you to prioritize your well-being, ensuring a healthy work-life balance.</li>
                                            <li><i class="icon-plus-sign"></i><strong>Pathways to Success:</strong> Your journey with us is brimming with growth opportunities. We're dedicated to nurturing your career and supporting your rise through the ranks.</li>
                                        </ul>
                                    </div>

                                    <p>Ready to embark on an enriching journey? Join the eFlex family and experience a workplace that values your individuality and success. Let's thrive together!</p>
                                    <router-link to="/careers/software-security-engineer/form" class="button button-3d m-0 button-primary" style="float:left">Apply Now</router-link>
                                </div>
                                <div class="divider divider-sm"></div>
                            </div>
						</div>
                        
                    </div>

				</div>
			</div>
		</section><!-- #content end -->

    </div>
</template>

<script>
import Sidebar from '@/components/CareerNav.vue'
export default {
    data() {
        return {
            
        }
    },
    components: {
        Sidebar
    },
    mounted() {
		window.scrollTo(0, 0)
	}
}
</script>